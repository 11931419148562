<!-- 教务 -- 考勤管理 -- 行课设置 -- 详情 -->
<template>
  <div class="flex" style="height: 100%">
    <div class="form-body" style="padding-top: 40rem">
      <div class="flex flex-align" style="margin-bottom: 40rem;padding-left: 30rem">
        <p style="font-size: 20rem;font-weight: bold">打卡详情</p>
        <el-image
            style="width: 28rem; height: 28rem;margin: 0 10rem 0 30rem"
            :src="icon"
            fit="contain">
        </el-image>
        <i style="font-size: 14rem;color: rgba(0,0,0,.4)">正常</i>
      </div>
      <el-form ref="form" label-width="150rem">
        <div style="padding-left: 36rem">
          <el-form-item label="迟到时间:">
            <p>{{ detailsData.start_at }}</p>
          </el-form-item>
          <el-form-item label="日期（星期）:">
            <p>{{ detailsData.end_at }}</p>
          </el-form-item>
          <el-form-item label="课节:">
            <p>{{ detailsData.creator }}</p>
          </el-form-item>
          <el-form-item label="教室:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="打卡时间:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="学生出勤数/学生总数:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="是否代课:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="是否补卡:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="补卡审批状态:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="补卡审批时间:">
            <p>{{ detailsData.created_at }}</p>
          </el-form-item>
          <el-form-item label="图片:">
            <el-image
                v-for="item in imgList"
                style="width: 160rem; height: 160rem;margin-right: 20rem"
                :src="item"
                fit="contain"
                :preview-src-list="imgList">
            </el-image>
          </el-form-item>
        </div>
      </el-form>
      <div class="footer-button">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <div class="m-right">
      <img src="@/assets/img/bg-2.png" alt="">
      <h4>个人信息</h4>
      <div v-if="true">
        <p><span>姓名</span>{{ detailsData.student_info }}</p>
        <p><span>校区</span>{{ detailsData.student_info }}</p>
        <p><span>工号</span>{{ detailsData.grade_info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@/assets/img/details-success.png'

export default {
	_config:{"route":{"path":"approval","meta":{"title":"审批"}}},
  data() {
    return {
      icon,
      detailsData: '',
      imgList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',]
    }
  },
  created() {
    // this.getData()
  },
  methods: {
    getData() {
      this.$_axios2.get('api/student/goal-setting/' + this.$route.query.id).then(res => {
        this.detailsData = res.data.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.knowledge {
  margin-top: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.m-right{
  background-color: #fff;
  height: 100%;
  width: 320rem;
  position: relative;
  overflow: hidden;
  padding: 30rem 0 0 30rem;
  margin: 0 40rem 0 20rem;
  h4{
    font-size: 20rem;
    margin-bottom: 40rem;
  }
  img{
    width: 100%;
    height: 88rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  p{
    color: rgba(0,0,0,.6);
    padding-left: 20rem;
    margin-top: 20rem;
  }
  span{
    display: inline-block;
    width: 100rem;
    color: rgba(0,0,0,.8);
    font-size: 14rem;
  }
}

@media only screen and (max-width: 1500rem) {
  .knowledge {
    width: 1200rem !important;
  }
}
</style>
